<template>
  <div id="agency">
    <main class="container-fluid">
        <div class="row h-100">
            <div class="w-100 align-self-center">
                <div class="d-flex justify-content-center">
                        <img id="img1" src="../assets/images/agency/agency-communication-ideas.svg" class="position-absolute" alt="">
                        <img id="img2" src="../assets/images/agency/agency-content-creators.svg" class="position-absolute" alt="">
                        <img id="img3" src="../assets/images/agency/agency-film-makers.svg" class="position-absolute" alt="">
                        <img id="img4" src="../assets/images/agency/agency-365on-services.svg" class="position-absolute" alt="">

                        <svg id="Ellipse" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="400" viewBox="0 0 400 400">
                            <defs>
                                <clipPath id="clip-Agency">
                                    <rect width="400" height="400"/>
                                </clipPath>
                            </defs>
                            <g data-name="Agency">
                                <g transform="translate(-760 -330)">
                                    <g class="cls-2" transform="translate(760 330)">
                                        <circle class="cls-8" cx="200" cy="200" r="200"/>
                                        <circle class="cls-9" cx="200" cy="200" r="199.5"/>
                                    </g>
                                    <g class="cls-2" transform="translate(785 355)">
                                        <circle class="cls-8" cx="175" cy="175" r="175"/>
                                        <circle class="cls-9" cx="175" cy="175" r="174.5"/>
                                    </g>
                                    <g class="cls-2" transform="translate(810 380)">
                                        <circle class="cls-8" cx="150" cy="150" r="150"/>
                                        <circle class="cls-9" cx="150" cy="150" r="149.5"/>
                                    </g>

                                    <mask id="myMask" transform="translate(760 580)">
                                        <rect x="0" y="-10" width="100%" height="19" fill="white" />
                                    </mask>
                                    <g class="cls-6" transform="translate(760 580)" mask="url(#myMask)">
                                        <g>
                                            <text id="text1" x="50%" y="0" dominant-baseline="middle" text-anchor="middle" data-name="COMMUNICATION IDEAS">COMMUNICATION IDEAS</text>
                                            <text id="text2" x="50%" y="0" dominant-baseline="middle" text-anchor="middle" data-name="SOCIAL STRATEGY">CONTENT CREATORS</text>
                                            <text id="text3" x="50%" y="0" dominant-baseline="middle" text-anchor="middle" data-name="FILM MAKER">FILM MAKERS</text>
                                            <text id="text4" x="50%" y="0" dominant-baseline="middle" text-anchor="middle" data-name="DIGITAL CONTENT">365 ON SERVICES</text>
                                        </g>
                                    </g>
                                    <text class="cls-7" transform="translate(960 617)"><tspan x="-20.781" y="0">skills</tspan></text>
                                </g>
                            </g>
                        </svg>
                </div>
            </div>
        </div>
    </main>

    <div class="bg-white text-dark">
      <div class="container pt-5 pb-5">
          <div class="row pt-5 pb-5">
              <div class="col-12 col-lg-4">
                  <div class="np-title">NOPROBLEMO</div>
                  <div class="playfairdisplay pfd-title">Agence créative</div>
              </div>
              <div class="col-12 col-lg-8">
                  <div class="italic-title mb-2">« Bousculer la communication en donnant vie aux images. »</div>
                  <p>Noproblemo est une agence de production audiovisuelle dédiée à l’image, mais pas que. Branding. Édition. Digital. Nous travaillons l'image, le design et les contenus sous toutes ses formes. Profils experts, équipe pluridisciplinaire, notre organisation et notre collectif permettent une approche sur mesure, créative et pragmatique.</p>
              </div>
          </div>
      </div>
    </div>

      <div class="container pt-5 pb-5">
          <div class="row pt-5 pb-5">
              <div class="col-12">
                  <div class="playfairdisplay pfd-title mb-4">Compétences</div>
              </div>
              <div class="col-12 col-lg-4 order-lg-2 my-auto">
                  <div class="mb-4"><img class="me-3" src="../assets/images/icon/ampoule.svg">COMMUNICATION IDEAS</div>
                  <div class="mb-4"><img class="me-3" src="../assets/images/icon/content.svg">CONTENT CREATORS</div>
                  <div class="mb-4"><img class="me-3" src="../assets/images/icon/Icn-cam.svg">FILM MAKERS</div>
                  <div class="mb-4"><img class="me-3" src="../assets/images/icon/calendrier.svg">365 ON SERVICES</div>
              </div>
              <div class="col-12 col-lg-8 order-xl-1 my-auto">
                  <p>Chaque jour qui passe est une occasion pour nous d'explorer de nouveaux territoires, de développer de nouvelles compétences, afin d'offrir le meilleur service à nos clients.</p>
                  <p>Notre objectif est de créer des contenus, des utilitaires et des plateformes qui sont mémorables parce qu'ils sont émotionnels. Précieux parce qu'ils sont personnalisés.</p>
                  <p>Engagés parce qu'ils sont interactifs. Et continus en les rendant disponibles sur n'importe quel écran.</p>
              </div>
          </div>
      </div>

    <div class="bg-white text-dark">
      <div class="container pt-5 pb-5">
          <div class="row pt-5 pb-5">
              <div class="col-12 pb-4">
                  <div class="playfairdisplay pfd-title">Nous sommes fiers de travailler avec</div>
              </div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/reworldmedia.jpg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/ftv.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/m6.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/tf1.svg" width="60"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/loreal.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/filorga.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/clinique.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/givenchy.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/omg.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/sonymusic.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/warner.svg"/></div>
              <div class="col-6 col-lg-2 text-center"><img src="../assets/images/logo/universal.svg"/></div>
          </div>
      </div>
    </div>
    <!-- Rencontrez notre équipe -->
    <!-- <div class="container pt-5">
      <div class="row pt-5">
          <div class="col-12 pb-4">
              <div class="playfairdisplay pfd-title">Rencontrez notre équipe</div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="(task, index) in creativesInfos" :key="task.name+index" :data-slide-marque="index">
              <div class="member" :style="{'background-image': 'url(' + task.img + ')'}">
                  <div class="infos">
                      <div class="title">{{ task.title }}</div>
                      <div class="name">{{ task.name }}</div>
                  </div>
              </div>
          </div>
      </div> 
    </div> -->
    <div class="container pt-5">
      <div class="row pt-5">
          <div class="col-12 text-center">
              <p>Vous avez une idée ou un projet ? Parlons-en !</p>
              <div class="work-title">TRAVAILLONS ENSEMBLE</div>
              <router-link to="/contact"><button class="bt-hover">CONTACTEZ-NOUS</button></router-link>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
    import gsap from 'gsap';

    export default {
        data() {
            return {
                creativesInfos: [
                    {
                        img: require('../assets/images/agency/team/jerome.jpg'),
                        title: 'PDG',
                        name: 'Jérôme',
                    },
                    {
                        img: require('../assets/images/agency/team/flavien.jpg'),
                        title: 'WEB DESIGNER',
                        name: 'Flavien',
                    },
                    {
                        img: require('../assets/images/agency/team/mikael.jpg'),
                        title: 'WEB DESIGNER',
                        name: 'Mikaël',
                    },
                    {
                        img: require('../assets/images/agency/team/pierre.jpg'),
                        title: 'MOTION DESIGNER',
                        name: 'Pierre',
                    },
                    {
                        img: require('../assets/images/agency/team/franck.jpg'),
                        title: 'MOTION DESIGNER',
                        name: 'Franck',
                    },
                    {
                        img: require('../assets/images/agency/team/damien.jpg'),
                        title: 'MOTION DESIGNER',
                        name: 'Damien',
                    },
                    {
                        img: require('../assets/images/agency/team/stephane.jpg'),
                        title: 'MOTION DESIGNER',
                        name: 'Stéphane',
                    },
                    {
                        img: require('../assets/images/agency/team/valerie.jpg'),
                        title: 'COMMUNITY MANAGER',
                        name: 'Valérie',
                    },
                    {
                        img: require('../assets/images/agency/team/tim.jpg'),
                        title: 'INGÉNIEUR SON',
                        name: 'Tim',
                    },
                ]
            }
        },
        mounted: function () {
            function animation(loopItem,loopItem2) {
                let tl = gsap.timeline();
                //...add animations hidden here...
                tl.to('#text'+loopItem, {duration: .5, y: 19, ease: 'none'}, '+=0');
                tl.to('#img'+loopItem, {duration: .5, opacity: 0}, '-=0.5');

                tl.to('.cls-2', {opacity: 0, stagger: 0.1}, '-=0.5');
                tl.to('#text'+loopItem, {duration: 0, y: -19, ease: 'none'}, '+=0');

                //...add animations apparent here...
                tl.to('#text'+loopItem2, {duration: .5, y: 0, ease: 'none'}, '-=0.6');
                tl.to('#img'+loopItem2, {duration: .5, opacity: 1});

                tl.to('.cls-2', {opacity: 0.2, stagger: -0.1}, '-=0.5');

                return tl;
            }

            // stitch them together in a master timeline...
            let master = gsap.timeline({repeat: -1});

            master.to(['#text1','#text2','#text3'], {duration: 0, y: -19});
            master.to(['#img1','#img2','#img3'], {duration: 0, opacity: 0});

            master.add(animation(4,1), '+=1.5')
                .addLabel('blueGreenSpin')
                .add(animation(1,2), '+=1.5')
                .add(animation(2,3), '+=1.5')
                .add(animation(3,4), '+=1.5')
                .currentLabel('blueGreenSpin');
        }
    }
</script>

<style lang="scss" scoped>
    .container-fluid{
        min-height: 540px;
    }

    // svg
    svg#Ellipse{
        fill: none;

        .cls-2 {
            stroke: #fff;
            opacity: 0.2;
        }

        .cls-6 {
            fill: #fff;
            font-size: 22px;
            font-family: Raleway-Bold, Raleway;
            font-weight: 700;

            text{
                text-align: center;
            }
        }

        .cls-7 {
            fill: #fff;
            font-size: 18px;
            font-family: PlayfairDisplay-Regular, Playfair Display;
            opacity: 0.5;
        }
    }


    //
    main {
        background-color: #131314;
        overflow: hidden;

        position: relative;
        width: 100%;
        height: 100vh;

        z-index: 1;
    }

    .np-title{
        font-size: 42px;
        font-weight: bolder;
    }

    .pfd-title{
        font-size: 32px;
    }

    .italic-title{
        font-size: 22px;
        font-weight: 500;
        font-style: italic;
    }

    p{
        font-size: 18px;
    }

    .np-circle{
        position: relative;
        padding-left: 20px;
        margin-right: 10px;
        height: 20px;
        border-radius: 50%;

        border: 1.8px solid rgba(255, 255, 255, 0.5);

        span{
            position: absolute;
            left: 7px;
            top: 6px;
            width: 6px;
            height: 6px;
            background-color: white;
            border-radius: 50%;
            border: 2px solid #B32572;
        }
    }

    .work-title{
        font-size: 42px;
        font-weight: bold;
    }


    /* button */
    .bt-hover{
        position: relative;
        background: none;
        padding: 25px 80px;
        display: inline-block;
        margin: 15px 30px;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        border: 1px solid #fff;
        color: #fff;
        transition: all 0.3s;

        &:hover{
            background: #fff;
            color: #131314;
        }
    }

    /* image */
    .img-center{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 400px;
        height: 400px;
        margin-top: -300px; /* Half the height */
        margin-left: -200px; /* Half the width */
    }

    /* membres */
    .team-title{
        font-size: 42px;
        font-weight: bold;
    }

    .member{
        position: relative;
        height: 420px;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .infos{
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px;
            background-color: rgba(0,0,0,0.5);

            .title{
                opacity: .7;
                font-size: 12px;
                margin-top: 1rem;
            }

            .name{
                cursor: pointer;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
</style>
